import React from 'react';

import Markdown from '../components/markdown';

class SectionHowWeWork extends React.Component {
  constructor(props) {
    super(props);

    // construct initial state from props
    let topics = [];
    let descriptions = [];

    if (this.props.tabbedSection) {
      this.props.tabbedSection.forEach((tab, i) => {
        topics.push(tab.topic);
        descriptions.push(tab.itemsList.childMarkdownRemark.html);
      });
    } else {
      topics = null;
      descriptions = null;
    }

    this.state = {
      topics,
      descriptions,
      activeIndex: 0,
    };

    this.switchTab = this.switchTab.bind(this);
  }

  switchTab(event, index) {
    // e.preventDefault();

    this.setState({
      activeIndex: index,
    });
  }

  render() {
    const { heading, label, tabbedSection } = this.props;

    return (
      <div className="section section-how-we-work">
        <div className="section-how-we-work__wrap">
          <p className="label">{label}</p>
          <h2>{heading}</h2>
          {this.state.topics && (
            <div className="section-how-we-work__tab-section">
              <div className="tab-section__topics">
                {this.state.topics.map((topic, index) => {
                  if (this.state.activeIndex === index) {
                    return (
                      <div
                        className="tab-section__topic tab-section__topic--is-active"
                        onClick={(event) => this.switchTab(event, index)}
                        key={index}
                      >
                        {topic}
                      </div>
                    );
                  }
                  return (
                    <div
                      className="tab-section__topic"
                      onClick={(event) => this.switchTab(event, index)}
                      key={index}
                    >
                      {topic}
                    </div>
                  );
                })}
              </div>
              <div className="tab-section__description">
                <Markdown markdown={this.state.descriptions[this.state.activeIndex]} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SectionHowWeWork;
