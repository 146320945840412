import React from 'react';
import Markdown from '../components/markdown';
import {
  GroupAnimation,
  GroupedTextAnimation,
  GroupedBlockAnimation,
} from '../components/animations/group-animation';

const SectionWhatToExpect = ({
  introTitle,
  introBody,
  featuresTitle,
  featuresList,
  buttonTarget,
  buttonLabel,
}) => {
  const features = featuresList.featuresList.match(/[^\r\n]+/g);

  return (
    <>
      <div className={'section section-what-to-expect'}>
        <GroupAnimation className={'section-what-to-expect__wrap'}>
          <GroupedTextAnimation as={'h2'}>{introTitle}</GroupedTextAnimation>
          <GroupedBlockAnimation>
            <Markdown className={'section-what-to-expect__wrap-p'} markdown={introBody.introBody} />
          </GroupedBlockAnimation>
        </GroupAnimation>
      </div>
      <div className={'section section-what-to-expect'}>
        <GroupAnimation className={'section-what-you-will-learn__wrap'}>
          <GroupedTextAnimation as={'h2'}>{featuresTitle}</GroupedTextAnimation>
          <GroupedBlockAnimation>
            {features.map((el, i) => (
              <p key={i} className={'section-what-you-will-learn__wrap-p'}>
                {el}
              </p>
            ))}
            <a className="button button--large" href={buttonTarget} rel="noreferrer noopener">
              {buttonLabel}
            </a>
          </GroupedBlockAnimation>
        </GroupAnimation>
      </div>
    </>
  );
};

export default SectionWhatToExpect;
