import React from 'react';
import {
  GroupAnimation,
  GroupedTextAnimation,
  GroupedBlockAnimation,
} from '../components/animations/group-animation';
import Break from '../components/break';

const SectionKeyFacts = ({ title, keyFacts, buttonLabel, buttonTarget }) => {
  return (
    <div className="section__wrapper">
      <div className="section section-key-facts">
        <GroupAnimation className={'section-key-facts__wrap'}>
          <GroupedTextAnimation as={'h2'} className={'section-key-facts__wrap-title'}>
            {title}
          </GroupedTextAnimation>
          <GroupedBlockAnimation className={'section-key-facts__wrap-content'}>
            {keyFacts.map((fact, i) => (
              <div key={i} className={'section-key-facts__content'}>
                <Break breakTitle={fact.title} />
                <GroupedTextAnimation as={'h2'}>{fact.paragraph.paragraph}</GroupedTextAnimation>
              </div>
            ))}
            <a className="button button--large" href={buttonTarget} rel="noreferrer noopener">
              {buttonLabel}
            </a>
          </GroupedBlockAnimation>
        </GroupAnimation>
      </div>
    </div>
  );
};

export default SectionKeyFacts;
