import React from 'react';

import Header from '../../components/header';
import Image from '../../components/image';
import Link from '../../components/link';
import SocialLinks from '../../components/social-links';
import { resolve } from '../../urls';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../../components/animations/group-animation';
import { DELAY_OFFSET } from '../../components/animations/config';

import SectionOpener from './section-opener';

const SectionOpenerOffices = (props) => {
  let {
    label,
    heading,
    introduction,
    image,
    items,
    contactEmail,
    devBlogUrl,
    facebookUrl,
    instagramUrl,
    jobsPageUrl,
    linkedinUrl,
    twitterUrl,
    whatRegion,
  } = props;

  if (whatRegion === 'us') {
    contactEmail = 'losangeles@edenspiekermann.com';
  }

  return (
    <SectionOpener openerType="offices">
      <div className="offices__header-wrap">
        <div className="offices__left-column">
          <Header
            label={label}
            heading={heading}
            introduction={introduction.childMarkdownRemark.html}
          />
          {image && <Image image={image} alt={image.title} />}
        </div>
        <GroupAnimation className="offices__right-column">
          {contactEmail && (
            <GroupedBlockAnimation className="offices__fieldSet" delay={DELAY_OFFSET}>
              <p>General inquiries</p>
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </GroupedBlockAnimation>
          )}

          {jobsPageUrl && (
            <GroupedBlockAnimation className="offices__fieldSet" delay={DELAY_OFFSET * 2}>
              <p>Careers</p>
              <Link to={resolve('jobs:list')}>View our job openings</Link>
            </GroupedBlockAnimation>
          )}

          {/* Render if at least 1 of the below exist */}
          {(facebookUrl || instagramUrl || linkedinUrl || twitterUrl || devBlogUrl) && (
            <GroupedBlockAnimation className="offices__fieldSet" delay={DELAY_OFFSET * 3}>
              <p>Follow us</p>
              <SocialLinks
                facebookUrl={facebookUrl}
                instagramUrl={instagramUrl}
                linkedinUrl={linkedinUrl}
                twitterUrl={twitterUrl}
                devBlogUrl={devBlogUrl}
              />
            </GroupedBlockAnimation>
          )}
        </GroupAnimation>
      </div>

      {items && (
        <div className="office-directory">
          {items.map((office, i) => {
            return (
              <GroupAnimation className="office-directory__office" key={i}>
                <GroupedBlockAnimation className="office-directory__animation">
                  <Link
                    to={resolve('offices:detail', { officeSlug: office.slug })}
                    className="office-directory__office-image"
                  >
                    <Image image={office.main_image} alt={office.main_image.title} />
                    <span className="office-directory__office-overlay">
                      <span className="office-directory__office-overlay-label">
                        <span className="button-icon">More</span>
                      </span>
                    </span>
                    <span className="office-directory__office-clock" />
                  </Link>
                </GroupedBlockAnimation>
                <dl className="office-directory__list">
                  <dt>City</dt>
                  <GroupedTextAnimation
                    delay={DELAY_OFFSET}
                    as="dd"
                    className="office-directory__office-city"
                  >
                    {office.city}
                  </GroupedTextAnimation>
                  <dt>Postal address</dt>
                  <GroupedBlockAnimation
                    delay={DELAY_OFFSET * 2}
                    as="dd"
                    className="office-directory__animation"
                  >
                    <div
                      className="office-directory__office-address"
                      dangerouslySetInnerHTML={{
                        __html: office.fullAddress.childMarkdownRemark.html,
                      }}
                    />
                  </GroupedBlockAnimation>
                  <GroupedBlockAnimation
                    delay={DELAY_OFFSET * 2}
                    className="office-directory__animation office-directory__office-contact"
                  >
                    <dt>Mail:</dt>
                    <dd>
                      <a
                        className="office-directory__office-email"
                        href={`mailto:${office.contact_email}`}
                      >
                        {office.contact_email}
                      </a>
                    </dd>

                    <dt>Phone number:</dt>
                    <dd>
                      <a
                        className="office-directory__office-phone"
                        href={`tel:${office.phone_number}`}
                      >
                        {office.phone_number}
                      </a>
                    </dd>
                  </GroupedBlockAnimation>
                </dl>
              </GroupAnimation>
            );
          })}
        </div>
      )}
    </SectionOpener>
  );
};

export default SectionOpenerOffices;
