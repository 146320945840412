import React from 'react';

import Image from '../../components/image';
import Markdown from '../../components/markdown';
import BlockAnimation from '../../components/animations/block-animation';

import SectionOpener from './section-opener';

const SectionOpenerBasicImage = (props) => {
  const { image, introduction } = props;

  return (
    <SectionOpener openerType="basic-image">
      <section className="section-opener-basic-image">
        {image && (
          <Image
            asBackground
            className="section-opener-basic-image__img"
            role="img"
            aria-label={image.description || ''}
            title={image.title || ''}
            image={image}
          />
        )}
        {introduction && (
          <BlockAnimation delay={500} className="section-opener-basic-image__header">
            <Markdown markdown={props.introduction.childMarkdownRemark.html} />
          </BlockAnimation>
        )}
      </section>
    </SectionOpener>
  );
};

export default SectionOpenerBasicImage;
