import { INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { useMemo } from 'react';

import { useRegion } from '../hooks/useRegion';
import { getLink } from '../urls';
import { Link } from './link';

const DEFAULT_RENDER_MARKS = {
  [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  [MARKS.ITALIC]: (text) => <em>{text}</em>,
};

const DEAFULT_RENDER_NODES = {};

const DEFAULT_RENDER_TEXT = (text) => {
  // Replace all linebreaks with <br />s.
  return text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]);
};

function renderEntryHyperlink(references) {
  return (node, children) => {
    const { whatRegion } = useRegion();
    const id = node?.data?.target?.sys?.id;
    const reference = references.find((ref) => ref.contentful_id === id);

    if (!id || !reference) {
      throw new Error('Reference not resolvable. Please provide a "contentful_id".');
    }

    const link = getLink(reference, whatRegion);
    return <Link link={link}>{children}</Link>;
  };
}

const Richtext = ({ raw, references = [] }) => {
  const document = useMemo(() => JSON.parse(raw), [raw]);
  const options = useMemo(
    () => ({
      renderMark: { ...DEFAULT_RENDER_MARKS },
      renderNode: {
        ...DEAFULT_RENDER_NODES,
        [INLINES.ENTRY_HYPERLINK]: renderEntryHyperlink(references),
      },
      renderText: DEFAULT_RENDER_TEXT,
    }),
    [references]
  );

  return documentToReactComponents(document, options);
};

export default Richtext;
