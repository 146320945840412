import classNames from 'classnames';
import {
  GroupAnimation,
  GroupedTextAnimation,
  GroupedBlockAnimation,
} from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';
import { array, arrayOf, func, oneOf, shape, string } from 'prop-types';
import React, { useState } from 'react';

const TabNavigation = ({ id: sectionId, heading, tabItems, appearance, TabPanelComponent }) => {
  const [activeTab, setActiveTab] = useState(0);

  const sectionTabNavClasses = classNames({
    'tab-navigation': true,
    [`tab-navigation--${appearance}`]: true,
  });

  return (
    <section className={sectionTabNavClasses}>
      <GroupAnimation className="tab-navigation__wrap">
        <GroupedTextAnimation
          as="h2"
          className={
            appearance === 'numbered'
              ? 'label tab-navigation__headline'
              : 'tab-navigation__headline'
          }
        >
          {heading}
        </GroupedTextAnimation>
        {tabItems && tabItems.length > 0 && (
          <div className="tab-navigation__tab-section">
            <ul role="tablist" className="tab-navigation__topics">
              {tabItems.map(({ id, tabHeading, tabTextContent }, index) => {
                return (
                  <GroupedBlockAnimation
                    as="li"
                    role="tab"
                    delay={DELAY_OFFSET * index}
                    tabIndex="0"
                    className={`tab-navigation__topic${
                      activeTab === index ? ' tab-navigation__topic--is-active' : ''
                    }`}
                    aria-selected={activeTab === index ? 'true' : 'false'}
                    aria-controls={`tab-section-${sectionId}-${index}`}
                    key={`tab-${id}`}
                  >
                    <div
                      onClick={() => setActiveTab(index)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 32) {
                          e.preventDefault();
                          setActiveTab(index);
                        }
                      }}
                    >
                      {tabHeading}
                      {appearance === 'numbered' ? (
                        <span className="tab-navigation__job-amount">{tabTextContent.length}</span>
                      ) : (
                        <svg
                          className={`tab-navigation__topic-arrow${
                            activeTab === index ? ' section-tab-nav__topic-arrow--is-active' : ''
                          }`}
                          viewBox="0 0 20 13"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.36 0L10 8.078 17.64 0 20 2.482 10 13 0 2.482 2.36 0z"
                          />
                        </svg>
                      )}
                    </div>
                  </GroupedBlockAnimation>
                );
              })}
            </ul>
            {tabItems.map(({ id, ...data }, index) => (
              <div
                role="tabpanel"
                id={`tab-section-${sectionId}-${index}`}
                key={`content-${id}`}
                className={`tab-navigation__content${
                  activeTab === index ? ' tab-navigation__content--active' : ''
                }`}
                aria-hidden={activeTab === index ? 'false' : 'true'}
              >
                <h3 className="tab-navigation__content-hidden-title">
                  {tabItems[activeTab].tabHeading}
                </h3>
                <TabPanelComponent {...data} />
              </div>
            ))}
          </div>
        )}
      </GroupAnimation>
    </section>
  );
};

export default TabNavigation;

TabNavigation.propTypes = {
  id: string.isRequired,
  heading: string.isRequired,
  appearance: oneOf(['numbered', undefined]),
  tabItems: arrayOf(
    shape({
      id: string.isRequired,
      tabHeading: string.isRequired,
      tabTextContent: array,
    }).isRequired
  ),
  TabPanelComponent: func.isRequired,
};

TabNavigation.defaultProps = {
  appearance: 'label',
  tabItems: [{ tabTextContent: [] }],
};
