import classNames from 'classnames';
import React from 'react';
import LazyLoad from 'react-lazyload';

import { resolve } from '../urls';

import Image from './image';
import Link from './link';

const attachCustomAttributes = (domNode) => {
  domNode && domNode.setAttribute('webkit-playsinline', true);
};

const FeaturedCaseStudyTile = (props) => {
  const { caseStudy, isTall } = props;

  const {
    title,
    slug,
    subtitle,
    main_image,
    tile_image_tall,
    tile_image_regular,
    main_image_is_dark,
    tile_video_regular,
    tile_video_tall,
  } = caseStudy;

  const tileClasses = classNames({
    'featured-project-tile': true,
    'featured-project-tile--dark-text': !main_image_is_dark,
    'featured-project-tile--tall': isTall,
  });

  let correctImg;
  if (isTall) {
    correctImg = tile_image_tall || tile_image_regular || main_image;
  } else {
    correctImg = tile_image_regular || main_image;
  }

  const correctVideo = isTall ? tile_video_tall : tile_video_regular;
  const hasVideo = !!correctVideo?.file?.url;

  return (
    <Link
      to={resolve('cases:detail', {
        caseSlug: slug,
      })}
      className={tileClasses}
    >
      <figure className="project-tile__figure" role="group">
        {!hasVideo && (
          <div className="featured-project-tile__image">
            <Image image={correctImg} alt={correctImg.title} />
          </div>
        )}
        {hasVideo && (
          <LazyLoad height={400}>
            <video
              className="featured-project-tile__video"
              src={correctVideo.file.url}
              poster={correctImg.file.url}
              playsInline
              muted
              autoPlay
              loop
              ref={attachCustomAttributes}
            />
          </LazyLoad>
        )}
        <figcaption className="featured-project-tile__overlay">
          <div className="featured-project-tile__meta">
            {title && <span className="featured-project-tile__title">{title}</span>}
            {subtitle && <p className="featured-project-tile__description">{subtitle}</p>}
          </div>
        </figcaption>
      </figure>
    </Link>
  );
};

export default FeaturedCaseStudyTile;
