import React from 'react';

const Break = ({ breakTitle }) => {
  return (
    <>
      <div className="break" />
      <p className="break__title">{breakTitle}</p>
    </>
  );
};

export default Break;
