import { useLocation } from '@reach/router';
import React from 'react';
import slugify from 'slugify';

import Image from '../components/image';
import Link from '../components/link';
import { getLink } from '../urls';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const ANIMATION_OFFSET = 125;
const SectionServicesInfobox = (props) => {
  const { heading, variation, infotextItems } = props;
  const { pathname } = useLocation();

  return (
    <section
      className={`section section-services-infobox${
        variation === 'Connected' && ' section-services-infobox--connected'
      }`}
    >
      <GroupAnimation className="section-services-infobox__container">
        <GroupedTextAnimation as="h2" className="section-services-infobox__header">
          {heading}
        </GroupedTextAnimation>
        <ul className="section-services-infobox__info-grid">
          {infotextItems &&
            infotextItems.length > 0 &&
            infotextItems.map((item, index) => {
              const {
                id,
                headline,
                text,
                link: { title },
                image,
              } = item;
              const link = getLink(item);
              const isActive = link.href === pathname;
              const delay = index * ANIMATION_OFFSET * 2;

              return (
                <GroupedBlockAnimation
                  as="li"
                  delay={delay + DELAY_OFFSET}
                  className={`section-services-infobox__cards-content${
                    isActive ? ' section-services-infobox__cards-content--selected' : ''
                  }`}
                  key={id}
                >
                  <div className="section-services-infobox__text-container">
                    <h3
                      className={`section-services-infobox__card-headline section-services-infobox__card-headline--${slugify(
                        headline,
                        { lower: true }
                      )}`}
                    >
                      {headline}
                    </h3>
                    <p className="section-services-infobox__card-text">{text}</p>
                    <div className="section-services-infobox__more">
                      <span className="button-icon">
                        {headline === 'Mobility' ? 'See our microsite' : 'More'}
                      </span>
                    </div>
                  </div>

                  {headline === 'Mobility' ? (
                    <a
                      href="https://mobility.edenspiekermann.com/"
                      className="section-services-infobox__link"
                      aria-label="Visit our microsite"
                      target="_blank"
                      rel="noreferrer noopener"
                    />
                  ) : (
                    <Link className="section-services-infobox__link" link={link}>
                      <span className="section-services-infobox__link-label">{title}</span>
                    </Link>
                  )}
                  <Image
                    className="section-services-infobox__cards-image"
                    image={image}
                    alt={image.description}
                    aria-hidden="true"
                  />
                </GroupedBlockAnimation>
              );
            })}
        </ul>
      </GroupAnimation>
    </section>
  );
};

export default SectionServicesInfobox;
