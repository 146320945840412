import React from 'react';

import Header from '../../components/header';
import Image from '../../components/image';
import Markdown from '../../components/markdown';

import SectionOpener from './section-opener';
import { DELAY_OFFSET } from '../../components/animations/config';
import TextAnimation from '../../components/animations/text-animation/text-animation';

const SectionOpenerIndustry = (props) => {
  const {
    label,
    heading,
    introduction,
    image,
    dark,
    headerBackgroundColor,
    headerTextColor,
    headerLabelColor,
  } = props;

  return (
    <div
      style={{
        backgroundColor: headerBackgroundColor || '#ffffff',
      }}
      className={`industry-opener__wrapper ${
        headerTextColor ? `industry-opener__wrapper--${headerTextColor}` : ''
      }`}
    >
      <SectionOpener openerType={`industry ${dark ? 'industry--dark' : ''}`}>
        <div className="industry-opener__content-flex-wrap">
          <div className="industry-opener__header-content-wrap">
            <Header heading={heading} label={label} headerLabelColor={headerLabelColor} />

            {introduction && introduction.childMarkdownRemark && (
              <TextAnimation as="p" delay={DELAY_OFFSET * 2}>
                <Markdown markdown={introduction.childMarkdownRemark.html} />
              </TextAnimation>
            )}
          </div>
        </div>
        <div className="industry-opener__img-flex-wrap">
          {image && image.file && (
            <div className="industry-opener__img-wrap">
              <Image className="industry-opener__img" image={image} alt={image.title} />
            </div>
          )}
        </div>
      </SectionOpener>
    </div>
  );
};

export default SectionOpenerIndustry;
