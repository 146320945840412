import React from 'react';

import Link from '../components/link';
import { GroupAnimation, GroupedBlockAnimation } from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

import Markdown from '../components/markdown';
import { resolve } from '../urls';

const SectionManifestoCTA = (props) => {
  const { text, image, ctaButtonText } = props;
  return (
    <div className="section section-manifesto-cta">
      <GroupAnimation className="section-manifesto-cta__copy">
        <GroupedBlockAnimation className="section-manifesto-cta__text-wrap">
          <Markdown
            className="section-manifesto-cta__text"
            markdown={text.childMarkdownRemark.html}
          />
        </GroupedBlockAnimation>
        <GroupedBlockAnimation className="section-manifesto-cta__button" delay={DELAY_OFFSET}>
          <Link className="button button--inverted" to={resolve('manifesto')}>
            {ctaButtonText}
          </Link>
        </GroupedBlockAnimation>
      </GroupAnimation>
    </div>
  );
};

export default SectionManifestoCTA;
