import React from 'react';

import Header from '../../components/header';

import SectionOpener from './section-opener';

const SectionOpenerCaseStudies = (props) => (
  <SectionOpener openerType="case-studies">
    <Header label={props.label} heading={props.heading} />
  </SectionOpener>
);

export default SectionOpenerCaseStudies;
