import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';

import Image from '../components/image';
import Markdown from '../components/markdown';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const ANIMATION_OFFSET = 125;

const SectionServicesCarousel = (props) => {
  const { heading, carouselCards } = props;
  const carouselItemsLength = carouselCards ? carouselCards.length : null;
  const fwdButton = useRef();
  const dotsContainer = useRef();

  const SliderArrow = ({ direction, ...props }) => {
    const { onClick } = props;

    return (
      <span
        className={`services-carousel__arrow-${direction}`}
        ref={
          // set the ref only to the forward button and only if the first click in not yet fired.
          // Once is clicked, the ref is set to 'null' and can't be clicked anymore
          direction === 'next' ? fwdButton : null
        }
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            if (!onClick) {
              return;
            }
            onClick();
          }
        }}
      />
    );
  };

  const CarouselCard = ({ text, image, heading }) => {
    const { cardImage } = image;

    return (
      <div className="services-carousel__slide">
        <header>
          {heading && <h3 className="services-carousel__slide-heading">{heading}</h3>}
          <Markdown
            className="services-carousel__slide-text"
            markdown={text.childMarkdownRemark.html}
          />
        </header>
        <Image
          className="services-carousel__slide-image"
          image={cardImage}
          alt={cardImage.description}
        />
      </div>
    );
  };

  const sliderSettings = {
    className: 'services-carousel__slider',
    accessibility: true,
    dots: true,
    appendDots: (dots) => (
      <div>
        <ul
          className={`services-carousel__dots${
            // adds a modifier that reduces the size of the dots if there are more than 6 carousel items
            carouselItemsLength > 6 ? ' services-carousel__dots--reduced' : ''
          }`}
          ref={dotsContainer}
        >
          {dots}
        </ul>
      </div>
    ),
    nextArrow: <SliderArrow direction="next" />,
    prevArrow: <SliderArrow direction="prev" />,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true,
    centerMode: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 621,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };

  useEffect(() => {
    // observe the dots (last elements of the carousel to appear in the viewport) and fire the callback (click + unobserve 0.5s)
    // eslint is complaining that IntersectionObserver is not defined, so we use it as a global window variable. Check also
    // if the browser support the IntersectionObserver method (currently not avaible on IE11)

    if (typeof window !== 'undefined' && window.IntersectionObserver) {
      const ob = new window.IntersectionObserver((payload) => {
        // this function is fired when the carousel dots are displayed on the screen. After 0.5s a click is fired
        // on the "next" button and then the dots container element is unobserved (only one click is possible)
        setTimeout(() => {
          if (payload[0].intersectionRatio > 0 && fwdButton.current) {
            fwdButton.current.click();
            // unobserve the dots element after the click (the function is fired only one time)
            ob.unobserve(payload[0].target);
          }
        }, 500);
      });

      // start observing the dots container
      fwdButton?.current && ob.observe(dotsContainer.current);
    }
  }, []);

  return (
    <section className="services-carousel">
      <GroupAnimation className="services-carousel__container">
        <GroupedTextAnimation as="h2" className="services-carousel__header">
          {heading}
        </GroupedTextAnimation>
        <Slider {...sliderSettings}>
          {carouselCards &&
            carouselCards.length > 0 &&
            carouselCards.map(({ id, heading, cardImage, cardText }, index) => {
              const delay = index * ANIMATION_OFFSET * 2;

              return (
                <GroupedBlockAnimation
                  key={id}
                  delay={delay + DELAY_OFFSET}
                  className="services-carousel__slide-wrapper"
                >
                  <CarouselCard text={cardText} heading={heading} image={{ cardImage }} />
                </GroupedBlockAnimation>
              );
            })}
        </Slider>
      </GroupAnimation>
    </section>
  );
};

export default SectionServicesCarousel;
