import React from 'react';

import Image from '../components/image';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const SectionClientProjectsList = (props) => {
  const { heading, label, clientsList } = props;
  return (
    <section className="section section-client-projects-list">
      <div className="section-client-projects-list__container">
        <GroupAnimation>
          <GroupedTextAnimation as="p" className="label">
            {label}
          </GroupedTextAnimation>
          <GroupedTextAnimation
            as="h2"
            delay={DELAY_OFFSET}
            className="meta-lockup__title section-client-projects-list__header"
          >
            {heading}
          </GroupedTextAnimation>
          <ul className="section-client-projects-list__info-grid">
            {clientsList &&
              clientsList.map(({ id, title, clientLogo, projectDescription }, i) => {
                return (
                  <GroupedBlockAnimation
                    as="li"
                    className="section-client-projects-list__cards-content"
                    key={id}
                    delay={(i + 2) * DELAY_OFFSET}
                  >
                    <h3 className="section-client-projects-list__card-title-hidden">{title}</h3>
                    <Image
                      className="section-client-projects-list__card-logo"
                      image={clientLogo}
                      alt={clientLogo.description}
                      objectFit="contain"
                      objectPosition="left center"
                    />
                    <p className="section-client-projects-list__card-project-description">
                      {projectDescription}
                    </p>
                  </GroupedBlockAnimation>
                );
              })}
          </ul>
        </GroupAnimation>
      </div>
    </section>
  );
};

export default SectionClientProjectsList;
