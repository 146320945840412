import React from 'react';
import {
  Accordion as Base,
  AccordionItemButton as Button,
  AccordionItemHeading as Heading,
  AccordionItem as Item,
  AccordionItemPanel as Panel,
} from 'react-accessible-accordion';

/**
 * "AccordionBase" is a wrapper for the "Accordion" component from
 * the "react-accessible-accordion" library. For all available options, please
 * refer to the documentation: https://www.npmjs.com/package/react-accessible-accordion
 */
export const AccordionBase = ({ children, ...props }) => (
  <Base className="accordion" {...props}>
    {children}
  </Base>
);

/**
 * "AccordionItem" is a wrapper for the "AccordionItem" component from
 * the "react-accessible-accordion" library. For all available options, please
 * refer to the documentation: https://www.npmjs.com/package/react-accessible-accordion
 */
export const AccordionItem = ({ children, ...props }) => (
  <Item className="accordion__item" {...props}>
    {children}
  </Item>
);

/**
 * "AccordionHeading" is a wrapper for the "AccordionItemHeading" component from
 * the "react-accessible-accordion" library. For all available options, please
 * refer to the documentation: https://www.npmjs.com/package/react-accessible-accordion
 */
export const AccordionHeading = ({ children, ...props }) => (
  <Heading className="accordion__heading" {...props}>
    {children}
  </Heading>
);

/**
 * "AccordionButton" is a wrapper for the "AccordionItemButton" component from
 * the "react-accessible-accordion" library. For all available options, please
 * refer to the documentation: https://www.npmjs.com/package/react-accessible-accordion
 */
export const AccordionButton = ({ children, ...props }) => (
  <Button className="accordion__button" {...props}>
    {children}
  </Button>
);

/**
 * "AccordionPanel" is a wrapper for the "AccordionItemPanel" component from
 * the "react-accessible-accordion" library. For all available options, please
 * refer to the documentation: https://www.npmjs.com/package/react-accessible-accordion
 */
export const AccordionPanel = ({ children, ...props }) => (
  <Panel className="accordion__panel" {...props}>
    {children}
  </Panel>
);
