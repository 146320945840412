import React from 'react';

import Header from '../../components/header';
import Image from '../../components/image';
import Link from '../../components/link';
import { resolve } from '../../urls';

import SectionOpener from './section-opener';

const SectionOpenerServices = (props) => {
  const { label, heading, image, items } = props;
  //  <div className="section-opener section-opener--services">
  return (
    <SectionOpener openerType="services">
      <div className="services__column-wrap">
        <div className="services__left-column">
          <Header label={label} heading={heading} />
          {image && <Image image={image} alt={image.title} />}
        </div>
        <div className="services__right-column">
          {items &&
            items.map((service, i) => (
              <div className="service__wrap" key={i}>
                <p className="label label--no-line">{service.serviceName}</p>
                <h3>{service.serviceActionTitle}</h3>
                <p>{service.serviceTeaserText.serviceTeaserText}</p>
                <Link
                  className="button-icon button-icon--inverted"
                  to={resolve('services:detail', {
                    serviceSlug: service.slug,
                  })}
                >
                  {service.ctaText}
                </Link>
              </div>
            ))}
        </div>
      </div>
    </SectionOpener>
  );
};

export default SectionOpenerServices;
