import React from 'react';

import { DELAY_OFFSET } from '../components/animations/config';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import Image from '../components/image';
import Link from '../components/link';
import { getLink } from '../urls';

const SectionOurClients = (props) => {
  const { heading, label, clientList, ctaButtonText, ctaButtonSlug } = props;
  return (
    <div className="section section-our-clients">
      <div className="partner-panel">
        <GroupAnimation className="partner-panel__head">
          <GroupedBlockAnimation as="p" className="label">
            {label}
          </GroupedBlockAnimation>
          <GroupedTextAnimation as="h2" delay={DELAY_OFFSET}>
            {heading}
          </GroupedTextAnimation>
        </GroupAnimation>

        <GroupAnimation>
          {clientList && (
            <div className="partner-panel__tile-container">
              {clientList.map((client, i) =>
                client.logo ? (
                  <GroupedBlockAnimation
                    className="partner-panel__tile"
                    key={i}
                    delay={(i + 2) * DELAY_OFFSET}
                  >
                    <Image
                      className="partner-panel__logo"
                      image={client.logo}
                      alt={client.logo.title}
                    />
                  </GroupedBlockAnimation>
                ) : null
              )}
            </div>
          )}

          {ctaButtonSlug && ctaButtonText && (
            <GroupedBlockAnimation
              className="partner-panel__link"
              delay={(clientList.length + 2) * DELAY_OFFSET}
            >
              <Link link={getLink(props)} className="button-icon button-icon--right" />
            </GroupedBlockAnimation>
          )}
        </GroupAnimation>
      </div>
    </div>
  );
};

export default SectionOurClients;
