import classNames from 'classnames';
import React from 'react';

import { buildLink } from '../urls';

import { DELAY_OFFSET } from './animations/config';
import GroupAnimation, {
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from './animations/group-animation';
import Link from './link';

const CarouselSlideOne = (props) => {
  const { logoBlack, slide } = props;

  const animateClasses = classNames({
    'slide1': true,
    'carousel-slide__slide-block': true,
    'animate': props.isAnimating || props.startInitialPlay,
  });

  const delay = 250;

  return (
    <div className={animateClasses}>
      <div className="carousel-slide__flex-wrap">
        <div className="carousel-slide__left-block">
          <div
            className="carousel-slide__left-layer-one"
            style={{
              backgroundImage: `url(${logoBlack.file.url})`,
            }}
          />
          <div className="carousel-slide__left-layer-two">
            <div className="carousel-slide__copy">
              <GroupAnimation className="carousel-slide__copy-wrap">
                {slide.label && (
                  <GroupedTextAnimation as="h4" delay={delay - DELAY_OFFSET * 3}>
                    {slide.label}
                  </GroupedTextAnimation>
                )}
                <GroupedTextAnimation as="h1" delay={delay}>
                  {slide.heading}
                </GroupedTextAnimation>
                {slide.ctaButtonText && (
                  <GroupedBlockAnimation
                    className="carousel-slide__links"
                    delay={delay + DELAY_OFFSET * 3}
                  >
                    <Link
                      className="button-icon carousel-slide__link"
                      link={buildLink(slide.ctaButtonText, slide.ctaButtonSlug)}
                    />
                  </GroupedBlockAnimation>
                )}
                {slide.subtitle && (
                  <GroupedBlockAnimation
                    className="carousel-slide__links"
                    delay={delay + DELAY_OFFSET * 3}
                  >
                    <GroupedTextAnimation as="p" delay={delay - DELAY_OFFSET * 6}>
                      {slide.subtitle}
                    </GroupedTextAnimation>
                  </GroupedBlockAnimation>
                )}
              </GroupAnimation>
            </div>
          </div>
        </div>
        <div className="carousel-slide__right-block">
          <div className="carousel-slide__right-layer-one">
            <div
              className="carousel-slide__img-wrap"
              style={{
                backgroundImage: `url(${slide.image.file.url})`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CarouselSlideOne;
