import PropTypes from 'prop-types';
import React from 'react';

// Import Section Components
import SectionOpenerAbout from './section-opener-about';
import SectionOpenerBasic from './section-opener-basic';
import SectionOpenerBasicImage from './section-opener-basic-image';
import SectionOpenerBasicImageRichText from './section-opener-basic-image-richtext';
import SectionOpenerCaseStudies from './section-opener-case-studies';
import SectionOpenerIndustry from './section-opener-industry';
import SectionOpenerManifesto from './section-opener-manifesto';
import SectionOpenerOffices from './section-opener-offices';
import SectionOpenerServices from './section-opener-services';

// Each case is the Content Type. Content Types are located in Contentful for each Section model.
const sectionOpenerConductor = (props, key, extraProps) => {
  switch (props.variation) {
    case 'Basic':
      return <SectionOpenerBasic key={key} {...props} {...extraProps} />;

    case 'Basic with Image':
      return <SectionOpenerBasicImage key={key} {...props} {...extraProps} />;

    case 'Basic with Image and Richtext':
      return <SectionOpenerBasicImageRichText key={key} {...props} {...extraProps} />;

    case 'Cases':
      return <SectionOpenerCaseStudies key={key} {...props} {...extraProps} />;

    case 'Form':
      return null;

    case 'Manifesto':
      return <SectionOpenerManifesto key={key} {...props} {...extraProps} />;

    case 'Navigation only':
      return null;

    case 'Offices':
      return <SectionOpenerOffices key={key} {...props} {...extraProps} />;

    case 'Services':
      return <SectionOpenerServices key={key} {...props} {...extraProps} />;

    case 'Industry':
      return <SectionOpenerIndustry key={key} {...props} {...extraProps} />;

    case 'Single Article':
      return null;

    case 'About':
      return <SectionOpenerAbout key={key} {...props} {...extraProps} />;

    case 'Single Case':
      return null;

    case 'Single Office':
      return null;

    case 'Single Service':
      return null;

    default:
      return null; // Todo - make default the "Basic" version
  }
};

export default sectionOpenerConductor;

// SectionOpenerConductor.propTypes = {
//   variation: PropTypes.string
// };
