import classNames from 'classnames';
import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from 'react-slick';

import CarouselSlideOne from '../components/carousel-slide-one';
import CarouselSlideThree from '../components/carousel-slide-three';
import CarouselSlideTwo from '../components/carousel-slide-two';

class SectionHomeCarousel extends Component {
  constructor(props) {
    super(props);

    // add isAnimating to each slide object
    const allSlides = this.props.slides.map((slide, i) => {
      slide.isAnimating = false;
      return slide;
    });

    this.state = {
      currentIndex: 0,
      slides: allSlides,
    };

    this.pauseSlider = this.pauseSlider.bind(this);
    this.startSlider = this.startSlider.bind(this);
    this.setAnimation = this.setAnimation.bind(this);
    this.adjustViewHeight = this.adjustViewHeight.bind(this);
  }

  componentDidMount() {
    // Workaround for mobile Safari/Chrome bottom bar
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

    // const vh = window.innerHeight * 0.01;
    // document.documentElement.style.setProperty('--vh', `${vh}px`);

    // window.addEventListener('resize', throttle(this.adjustViewHeight, 100), false);

    const firstSlide = document.querySelector('.slide1');
    // console.log('TYPE: ', typeof this.props.skipIntro);
    // console.log('WHAT IS IT', this.props.skipIntro);

    if (this.props.skipIntro) {
      // console.log('SKIP INTRO, GO TO TRANSITION IMEDDIATELY');

      setTimeout(() => {
        // set first slide animation in motion
        this.setAnimation(0);
        this.slider.slickPlay();
        document.body.classList.remove('fixed');
      }, 300);
    } else {
      // console.log('DONT SKIP INTRO, WAIT FOR INTRO SCREEN TO FINISH');

      setTimeout(() => {
        // console.log('set timeout started. starting slick player');
        // set first slide animation in motion
        this.setAnimation(0);
        document.body.classList.remove('fixed');
        this.slider.slickPlay();
      }, 3000);
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.adjustViewHeight);
  }

  adjustViewHeight() {
    if (!this.element) return;

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  componentWillReceiveProps(nextProps) {
    // if (this.props.startInitialPlay) {
    //   this.startSlider();
    // }
    // console.log('componentWilllReceiveProps, SectionHomeCarousel', nextProps);
    // if (nextProps.introCompleted) {
    //   const allSlides = this.state.slides.map((slide, i) => {
    //     if (i === 0) {
    //       slide['isAnimating'] = true;
    //     } else {
    //       slide['isAnimating'] = false;
    //     }
    //     return slide;
    //   });
    //   this.setState(
    //     {
    //       slides: allSlides,
    //     },
    //     () => alert('LFG?')
    //   );
    // }
    // if (nextProps.introCompleted) {
    //   const fuckit = document.querySelector('.slide1');
    //   setTimeout(() => {
    //     console.log('set timeout started. starting slick player');
    //     fuckit.classList.add('animate');
    //     this.slider.slickPlay();
    //   }, 2000);
    // }
  }

  startSlider() {
    this.slider.slickPlay();
  }

  pauseSlider() {
    this.slider.slickPause();
  }

  setAnimation(index) {
    const newSlidesState = this.state.slides.map((slide, i) => {
      if (i === index) {
        return { ...slide, isAnimating: true };
      }
      return { ...slide, isAnimating: false };
    });

    this.setState({
      slides: newSlidesState,
      currentIndex: index,
    });
  }

  render() {
    const { scrollDownButtonText, espiReLogoRed, espiReLogoBlack, slides } = this.props;

    const downButtonClasses = classNames({
      'section-home-carousel__down-button': true,
      'section-home-carousel__down-button--black':
        this.state.currentIndex === 1 ||
        this.state.currentIndex === 4 ||
        this.state.currentIndex === 7,
    });
    const sliderSettings = {
      className: 'section-home-carousel__slider',
      accessibility: true,
      dots: true,
      appendDots: (dots) => <ul className="section-home-carousel__dots">{dots}</ul>,
      infinite: true,
      speed: 250,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      // vertical: true,
      // autoplay: true,
      autoplay: true,
      autoplaySpeed: 5000,
      // beforeChange: (current, next) => {console.log('sick!')}
      afterChange: (newIndex) => {
        this.setAnimation(newIndex);
      },
    };

    return (
      <div className="section section-home-carousel" ref={(element) => (this.element = element)}>
        <Slider ref={(slider) => (this.slider = slider)} {...sliderSettings}>
          {this.state.slides.map((slide, i) => {
            if (i % 3 === 0) {
              return (
                <CarouselSlideOne
                  key={i}
                  slideIndex={i}
                  slide={slide}
                  isAnimating={slide.isAnimating}
                  logoBlack={espiReLogoBlack}
                  logoRed={espiReLogoRed}
                  startInitialPlay={this.props.startInitialPlay}
                />
              );
            } else if (i % 3 === 1) {
              return (
                <CarouselSlideTwo
                  key={i}
                  slideIndex={i}
                  slide={slide}
                  isAnimating={slide.isAnimating}
                  logoBlack={espiReLogoBlack}
                  logoRed={espiReLogoRed}
                />
              );
            } else {
              return (
                <CarouselSlideThree
                  key={i}
                  slideIndex={i}
                  slide={slide}
                  isAnimating={slide.isAnimating}
                  logoBlack={espiReLogoBlack}
                  logoRed={espiReLogoRed}
                />
              );
            }
          })}
        </Slider>
        <div className={downButtonClasses}>
          <AnchorLink href="#scrollToFirstSection" className="button-down-arrow">
            {scrollDownButtonText}
          </AnchorLink>
        </div>
      </div>
    );
  }
}

export default SectionHomeCarousel;
