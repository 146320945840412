import React, { useMemo } from 'react';

import { DELAY_OFFSET, DURATION } from '../components/animations/config';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import Link from '../components/link';
import { getLink } from '../urls';

const splitLines = (text) => text?.split('\n') || [];

const buildLines = (lines) =>
  lines.map((line) => (
    <React.Fragment key={line}>
      {line}
      <br />
    </React.Fragment>
  ));

const SectionHomepageMessage = (props) => {
  const { header, subheader, ctaLabel, ctaInternalLink } = props;

  const { total, Header, Subheader } = useMemo(() => {
    const headerLines = splitLines(header?.header);
    const subheaderLines = splitLines(subheader?.subheader);

    return {
      total: headerLines.length + subheaderLines.length,
      Header: () => buildLines(headerLines),
      Subheader: () => buildLines(subheaderLines),
    };
  }, [header, subheader]);

  return (
    <div className="section section-homepage-message">
      <GroupAnimation className="section-homepage-message__content">
        {header?.header && (
          <GroupedTextAnimation as="h3" delay={0}>
            <Header />
          </GroupedTextAnimation>
        )}
        {subheader?.subheader && (
          <GroupedTextAnimation as="h3" delay={header?.header ? DELAY_OFFSET : 0}>
            <Subheader />
          </GroupedTextAnimation>
        )}
        {ctaLabel && ctaInternalLink && ctaInternalLink.url && (
          <GroupedBlockAnimation
            className="section-homepage-message__links"
            delay={total * DELAY_OFFSET}
          >
            <Link className="button section-homepage-message__cta" link={getLink(props)} />
          </GroupedBlockAnimation>
        )}
      </GroupAnimation>
    </div>
  );
};

export default SectionHomepageMessage;
