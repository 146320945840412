import React from 'react';

import TabNavigation from '../components/tab-navigation';
import { GroupedBlockAnimation } from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const TabPanel = ({ tabTextContent }) => {
  return tabTextContent.map(({ id, headline, text }, index) => {
    return (
      <GroupedBlockAnimation delay={DELAY_OFFSET * 2 * index} key={id} className="section-tab-nav">
        <h4 className="section-tab-nav__title">{headline}</h4>
        <p className="section-tab-nav__body">{text}</p>
      </GroupedBlockAnimation>
    );
  });
};

const SectionTabNav = ({ id, heading, tabItems }) => (
  <TabNavigation id={id} heading={heading} tabItems={tabItems} TabPanelComponent={TabPanel} />
);

export default SectionTabNav;
