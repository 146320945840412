import React from 'react';
import BlockAnimation from '../components/animations/block-animation';

import FeaturedCaseStudyTile from '../components/featured-case-study-tile';

const SectionCuratedCaseStudies = (props) => {
  const { featuredCaseStudies } = props;

  return (
    <div className="section section project-overview">
      <div className="featured-projects-block">
        <ul className="featured-projects-block__list">
          {featuredCaseStudies.map((caseStudy, i) => {
            const isTall = i % 2 === 0;
            return (
              <BlockAnimation as="li" delay={300} key={i} className="featured-projects-block__item">
                <FeaturedCaseStudyTile caseStudy={caseStudy} isTall={isTall} />
              </BlockAnimation>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SectionCuratedCaseStudies;
