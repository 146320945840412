import React from 'react';

import Image from '../../components/image';
import Link from '../../components/link';
import BlockAnimation from '../../components/animations/block-animation';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../../components/animations/group-animation';
import { DELAY_OFFSET } from '../../components/animations/config';
import Markdown from '../../components/markdown';

import SectionOpener from './section-opener';

const SectionOpenerAbout = (props) => {
  const { label, heading, image, introduction, text, buttonText, buttonSlug } = props;

  return (
    <SectionOpener openerType="about">
      <div className="section-opener-about__hello">
        {image && (
          <div className="section-opener-about__img-wrap">
            <Image className="section-opener-about__img" image={image} alt={image.title} />
          </div>
        )}
        {introduction && (
          <BlockAnimation delay={500} className="section-opener-about__hello-content">
            <Markdown
              className="section-opener-about__header"
              markdown={introduction.childMarkdownRemark.html}
            />
          </BlockAnimation>
        )}
      </div>
      <div className="about-us-section">
        <div className="about-us-section__content">
          <GroupAnimation>
            {label && (
              <GroupedBlockAnimation className="label label--invert">{label}</GroupedBlockAnimation>
            )}
            {heading && (
              <GroupedTextAnimation as="h2" delay={DELAY_OFFSET}>
                {heading}
              </GroupedTextAnimation>
            )}
            {text && (
              <GroupedTextAnimation delay={DELAY_OFFSET * 2} className="about-us-section__text">
                <Markdown markdown={text.childMarkdownRemark.html} />
              </GroupedTextAnimation>
            )}
            {buttonText && buttonSlug && (
              <GroupedBlockAnimation className="about-us-section__button" delay={DELAY_OFFSET * 3}>
                <Link className="button button--inverted" to={buttonSlug}>
                  {buttonText}
                </Link>
              </GroupedBlockAnimation>
            )}
          </GroupAnimation>
        </div>
      </div>
    </SectionOpener>
  );
};

export default SectionOpenerAbout;
