import React from 'react';

import Link from '../components/link';
import { resolve } from '../urls';

const SectionCaseStudiesLists = (props) => {
  const { sectionHeader, caseStudiesByCategory } = props;

  return (
    <div className="section section-case-studies-list">
      <div className="section-case-studies-list__wrap">
        <p className="label label--invert-with-red-line" />

        {sectionHeader && <h2>{sectionHeader}</h2>}
        {caseStudiesByCategory && (
          <div className="section-case-studies-list__list-wrap">
            {caseStudiesByCategory.map((list, i) => (
              <div className="section-case-studies-list__list" key={i}>
                <h4>{list.category}</h4>

                {list.projects &&
                  list.projects.map((project, i) => {
                    if (project.slug) {
                      return (
                        <Link
                          key={i}
                          className="section-case-studies-list__link"
                          to={resolve('cases:detail', {
                            caseSlug: project.slug,
                          })}
                        >
                          {project.title}
                        </Link>
                      );
                    }
                    return (
                      <div
                        key={i}
                        className="section-case-studies-list__link section-case-studies-list__link--inactive"
                      >
                        {project.title}
                      </div>
                    );
                  })}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionCaseStudiesLists;
