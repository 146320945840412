import classNames from 'classnames';
import React from 'react';

import EdnaInputCheckbox from './edna-input-checkbox';
import Markdown from './markdown';

const Form = (props) => {
  const {
    formActionUrl,
    emailFieldName,
    fullNameField,
    companyFieldName,
    roleFieldName,
    motivationFieldName,
    theme,
    button_text,
    invertedButton,
    privacyNotice,
    consentCheckBoxes,
    // Below are react-mailchimp-subscribe props
    status,
    message,
    onSubmitted,
    onValidated,
  } = props;

  // Form Refs
  const fullNameRef = React.createRef();
  const roleFieldRef = React.createRef();
  const companyRef = React.createRef();
  const emailRef = React.createRef();
  const motivationFieldRef = React.createRef();
  const formRef = React.createRef();

  const formClasses = classNames({
    form: true,
    [`form--over-${theme}`]: !!theme,
  });

  const formButtonClasses = classNames({
    'form__button': true,
    'button': true,
    'button--inverted': !!invertedButton, // check truthiness of invertedButton prop
    // 'button--alternative': !invertedButton
  });

  // Todo - add validation, and dynamic onSubmitted fields
  const onSubmitHandler = (e) => {
    if (onSubmitted) {
      e.preventDefault();
      if (formRef.current?.checkValidity()) {
        onSubmitted(formRef.current);
      } else {
        formRef.current.reportValidity();
      }
    }
  };

  // emailRef &&
  // emailRef.value.indexOf('@') > -1 &&

  return (
    <form
      action={formActionUrl}
      className={formClasses}
      method="post"
      data-netlify="true"
      name="Masterclass Application"
      ref={formRef}
    >
      <input type="hidden" name="form-name" value="Masterclass Application" />
      <div className="form__inputs">
        <div className="form__inputs--wrap">
          {fullNameField && (
            <div className="form__input-row form__input--spaced">
              <div className="edna-input-text">
                <input
                  className="edna-input-text__input"
                  ref={fullNameRef}
                  placeholder="Your full name*"
                  type="text"
                  id="form-fname"
                  name="Name"
                  required
                />
                <label className="edna-input-text__label" htmlFor="form-fname">
                  Your full name<span className="form__input--required">*</span>
                </label>
              </div>
            </div>
          )}

          {emailFieldName && (
            <div className="form__input-row">
              <div className="edna-input-text">
                <input
                  className="edna-input-text__input"
                  ref={emailRef}
                  placeholder="Your emaill address*"
                  type="email"
                  name="Email"
                  id="form-email"
                  required
                />
                <label className="edna-input-text__label" htmlFor="form-email">
                  Your emaill address<span className="form__input--required">*</span>
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="form__inputs--wrap">
          {roleFieldName && (
            <div className="form__input-row form__input--spaced">
              <div className="edna-input-text">
                <input
                  className="edna-input-text__input"
                  ref={roleFieldRef}
                  placeholder="Your role*"
                  type="text"
                  name="Role"
                  id="form-fname"
                  required
                />
                <label className="edna-input-text__label" htmlFor="form-fname">
                  Your role<span className="form__input--required">*</span>
                </label>
              </div>
            </div>
          )}

          {companyFieldName && (
            <div className="form__input-row">
              <div className="edna-input-text">
                <input
                  className="edna-input-text__input"
                  ref={companyRef}
                  placeholder="Company*"
                  type="text"
                  id="form-company"
                  name="Company"
                  required
                />
                <label className="edna-input-text__label" htmlFor="form-company">
                  Company<span className="form__input--required">*</span>
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="form__inputs--wrap form__inputs--wrap-motivation">
          {motivationFieldName && (
            <div className="form__input-row form__input--motivation-field form__input--spaced">
              <div className="edna-input-text">
                <input
                  className="edna-input-text__input"
                  ref={motivationFieldRef}
                  placeholder="Your motivation"
                  name="Motivation"
                  type="text"
                  id="form-fname"
                />
                <label className="edna-input-text__label" htmlFor="form-fname">
                  Your motivation
                </label>
              </div>
            </div>
          )}

          {button_text && (
            <div className="form__submit">
              <button
                className={formButtonClasses}
                id="mc-embedded-subscribe"
                type="submit"
                onClick={onSubmitHandler}
                aria-label={button_text}
              >
                {button_text}
              </button>
            </div>
          )}
        </div>
      </div>

      {consentCheckBoxes && (
        <div className="form__consents">
          {consentCheckBoxes.map((checkbox, i) => (
            <div className="form__input-row form__input-checkbox" key={i}>
              <EdnaInputCheckbox {...checkbox}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: checkbox.label.childMarkdownRemark.html,
                  }}
                />
              </EdnaInputCheckbox>
            </div>
          ))}
        </div>
      )}

      {privacyNotice && (
        <Markdown
          className="form__privacy-notice"
          markdown={privacyNotice.childMarkdownRemark.html}
        />
      )}
    </form>
  );
};

export default Form;
