import anime from 'animejs';
import { Carousel as Caroucssel } from 'caroucssel';
import { Mask } from 'caroucssel/features/mask';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { arrayOf, exact, number, shape, string } from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import Image from '../components/image';
import Richtext from '../components/richtext';
import { usePolyfill } from '../hooks/usePolyfill';
import usePrefersReducedMotion from '../hooks/usePrefersReducedMotion';

const ANIMATION_DELAY_INITIAL = 1000; // ms
const ANIMATION_DELAY_AFTER_INTERACTION = 2000; // ms
const ANIMATION_SPEED_PER_PIXEL = 40 / 1000; // px/ms
const ANIMATION_EASING = 'easeInOutQuad';
const DIRECTION_RIGHT = 'r';
const DIRECTION_LEFT = 'l';

const useCarousel = () => {
  const ref = useRef(null);
  const instance = useRef(null);

  // To work properly, the caroucssel library is using the "native" scroll-behaviour.
  // If a browser doesn't support this, we are loading a polyfill for that...
  usePolyfill(
    () => 'scrollBehavior' in document.documentElement.style,
    () =>
      import(/* webpackChunkName: "smoothscroll-polyfill" */ 'smoothscroll-polyfill').then(
        (module) => {
          module.default.polyfill();
          return module;
        }
      )
  );

  // Create instance of caroucssel library (to hide the scrollbars on all browsers)
  useEffect(() => {
    if (instance.current || !ref.current) {
      return;
    }

    instance.current = new Caroucssel(ref.current, {
      features: [new Mask({ className: 'mask' })],
    });
  }, [instance, ref.current]);

  useEffect(
    () => () => {
      if (!instance.current) {
        return;
      }

      instance.current.destroy();
    },
    []
  );
  return ref;
};

const useDragScrollAnimation = (ref) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = useRef(null);
  const direction = useRef(DIRECTION_RIGHT);

  const animate = useCallback((delay = 0) => {
    stopAniamtion();

    if (prefersReducedMotion) {
      return;
    }

    const width = ref.current.scrollWidth - ref.current.clientWidth;
    const left = ref.current.scrollLeft;

    let distance = width - left;
    let scrollLeft = width;
    if (direction.current === DIRECTION_LEFT) {
      distance = left;
      scrollLeft = 0;
    }

    const duration = Math.abs(distance / ANIMATION_SPEED_PER_PIXEL);
    const el = {
      scrollLeft: left,
    };

    animation.current = anime({
      targets: el,
      scrollLeft,
      duration,
      delay,
      easing: ANIMATION_EASING,
      update: () => {
        ref.current.scrollLeft = el.scrollLeft;
      },
    });

    animation.current.finished.then(() => {
      direction.current = direction.current === DIRECTION_LEFT ? DIRECTION_RIGHT : DIRECTION_LEFT;
      animate();
    });
  }, []);

  const stopAniamtion = useCallback(() => {
    animation.current && animation.current.pause();
    animation.current = null;
  }, []);

  const onStart = useCallback((e) => {
    stopAniamtion();
    let lastMousePosition = e.clientX || e.touches[0].clientX;

    const onMove = (e) => {
      const clientX = e.clientX || e.touches[0].clientX;

      direction.current = lastMousePosition <= clientX ? DIRECTION_LEFT : DIRECTION_RIGHT;

      if (e.type === 'mousemove') {
        ref.current.scrollLeft += lastMousePosition - clientX;
      }
      lastMousePosition = clientX;
    };

    const onEnd = () => {
      ref.current.style.cursor = 'grab';
      ref.current.style.removeProperty('user-select');
      window.removeEventListener('touchmove', onMove);
      window.removeEventListener('mousemove', onMove);
      window.removeEventListener('touchend', onEnd);
      window.removeEventListener('mouseup', onEnd);
    };

    lastMousePosition = e.clientX;
    ref.current.style.cursor = 'grabbing';
    ref.current.style.userSelect = 'none';
    window.addEventListener('touchmove', onMove, { passive: true });
    window.addEventListener('mousemove', onMove, { passive: true });
    window.addEventListener('touchend', onEnd, { passive: true });
    window.addEventListener('mouseup', onEnd, { passive: true });
  });

  const onWheel = useCallback((e) => {
    const delta = Math.abs(e.deltaX);
    if (delta <= 5) {
      return;
    }

    stopAniamtion();
  }, []);

  const onScrollEnd = useCallback(
    debounce(() => {
      animate(ANIMATION_DELAY_AFTER_INTERACTION);
    }, 250),
    []
  );

  const onScroll = useCallback(() => {
    if (animation.current) {
      return;
    }

    onScrollEnd();
  });

  useEffect(() => {
    animate(ANIMATION_DELAY_INITIAL);

    return stopAniamtion;
  }, []);

  return {
    onTouchStart: onStart,
    onMouseDown: onStart,
    onWheel,
    onScroll,
  };
};

const SectionCollage = ({ images, text }) => {
  const element = useCarousel();
  const animationProps = useDragScrollAnimation(element);

  return (
    <div
      className={classNames([
        'section',
        'section-collage',
        { 'section-collage--with-text': !!text?.raw },
      ])}
    >
      <div className="section-collage__inner" ref={element} {...animationProps}>
        {images.map((image, i) => (
          <Image
            key={i}
            asBackground
            tag="span"
            image={image}
            className={`section-collage__image section-collage__image-${i}`}
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          />
        ))}
      </div>

      {text?.raw && (
        <div className="section-collage__text copy">
          <Richtext raw={text.raw} references={text.references} />
        </div>
      )}
    </div>
  );
};

export default SectionCollage;

SectionCollage.propTypes = {
  images: arrayOf(
    shape([
      // static file only:
      exact({
        file: shape({
          contentType: string.isRequired,
          url: string.isRequired,
          details: shape({
            image: shape({
              width: number.isRequired,
              height: number.isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired,
      }),
      // the gatsby fixed image
      exact({
        file: shape({
          contentType: string.isRequired,
        }).isRequired,
        fixed: shape({
          base64: string,
          height: number.isRequired,
          width: number.isRequired,
          src: string.isRequired,
          srcSet: string.isRequired,
          srcSetWebp: string,
          srcWebp: string,
        }).isRequired,
      }),
      // the gatsby fluid image
      exact({
        file: shape({
          contentType: string.isRequired,
        }).isRequired,
        fluid: shape({
          base64: string,
          sizes: string.isRequired,
          src: string.isRequired,
          srcSet: string.isRequired,
          srcSetWebp: string,
          srcWebp: string,
        }).isRequired,
      }),
    ])
  ).isRequired,
};
