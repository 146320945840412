import React from 'react';
import { GroupAnimation, GroupedBlockAnimation } from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const ANIMATION_OFFSET = 250;

const SectionNumberedList = (props) => {
  const { heading, listItems, dark, numberedListBg, numberedListText, listNumberColor } = props;

  // When dark is true, text should be smaller.
  // Rename to avoid confusion
  const smallVariant = dark;

  return (
    <section
      className={`section-numbered-list ${smallVariant ? 'section-numbered-list--small' : ''}`}
      style={{
        backgroundColor: numberedListBg || '#000',
      }}
    >
      <div
        className={`section-numbered-list__container ${
          numberedListText ? `section-numbered-list__container--${numberedListText}` : ''
        }`}
      >
        <h2 className="section-numbered-list__header">{heading}</h2>
        <GroupAnimation as="ol" className="section-numbered-list__info-grid">
          {listItems &&
            listItems.length > 0 &&
            listItems.map(({ id, listItemText }, index) => {
              // we add '0' only if the list has 1 digit (1 --> 01), otherwise
              // we keep it as it is (17 --> 17)
              const stringIndex = `${index + 1}`;
              const prefixValue = stringIndex.length === 1 ? `0${stringIndex} ` : `${stringIndex} `;

              const delay = index * ANIMATION_OFFSET;

              return (
                <GroupedBlockAnimation
                  as="li"
                  className="section-numbered-list__cards-content"
                  key={id}
                  delay={delay + DELAY_OFFSET * 2}
                >
                  {listNumberColor ? (
                    <span
                      className="section-numbered-list__item-number"
                      aria-hidden="true"
                      style={{ color: listNumberColor }}
                    >
                      {prefixValue}
                    </span>
                  ) : (
                    <span className="section-numbered-list__item-number" aria-hidden="true">
                      {prefixValue}
                    </span>
                  )}
                  {listItemText}
                </GroupedBlockAnimation>
              );
            })}
        </GroupAnimation>
      </div>
    </section>
  );
};

export default SectionNumberedList;
