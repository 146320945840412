import React from 'react';

import Image from '../components/image';
import Markdown from '../components/markdown';

const SectionOurApproach = (props) => {
  const { heading, label, image, body } = props;
  return (
    <div className="section section-our-approach">
      <div className="section-our-approach__content-container">
        <div className="section-our-approach__copy-wrap">
          <div className="label">{label}</div>
          <h2>{heading}</h2>
          <Markdown markdown={body.childMarkdownRemark.html} />
        </div>
        <div className="section-our-approach__img-wrap">
          <Image image={image} alt={image.title} />
        </div>
      </div>
    </div>
  );
};

export default SectionOurApproach;
