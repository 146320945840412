import React from 'react';
import BlockAnimation from '../components/animations/block-animation';

const SectionManifesto = (props) => (
  <div className="section section-manifesto">
    <ul>
      {props.items &&
        props.items.map((item, index) => {
          return (
            <BlockAnimation as="li" key={index} className="section-manifesto__item">
              <p className="section-manifesto__item-number">0{index + 1}</p>
              <div className="section-manifesto__item-text-wrap">
                <h3>{item.title}</h3>
                <div
                  className="section-manifesto__teaser"
                  dangerouslySetInnerHTML={{
                    __html: item.description.childMarkdownRemark.html,
                  }}
                />
              </div>
            </BlockAnimation>
          );
        })}
    </ul>
  </div>
);

export default SectionManifesto;
