import PropTypes from 'prop-types';
import React from 'react';

import BlockStatementCollage from '../blocks/block-statement-collage';

import SectionArrowText from './section-arrow-text';
import SectionArticleTeaser from './section-article-teaser';
import SectionCaseStudiesLists from './section-case-studies-lists';
import SectionClientProjectsList from './section-client-projects-list';
import SectionCollage from './section-collage';
import SectionContactUs from './section-contact-us';
import SectionCTA from './section-cta';
import SectionCuratedCaseStudies from './section-curated-case-studies';
import SectionGenericCTA from './section-generic-cta';
import SectionHomeCarousel from './section-home-carousel';
import SectionHomepageMessage from './section-homepage-message';
import SectionHowWeWork from './section-how-we-work';
import SectionJobList from './section-job-list';
import SectionKeyFacts from './section-key-facts';
import SectionManifesto from './section-manifesto';
import SectionManifestoCTA from './section-manifesto-cta';
import SectionMasterclassForm from './section-masterclass-form';
import SectionNumberedList from './section-numbered-list';
import SectionOfficeList from './section-office-list';
import sectionOpenerConductor from './section-opener/section-opener-conductor';
import SectionOurApproach from './section-our-approach';
import SectionOurClients from './section-our-clients';
import SectionOurServices from './section-our-services';
import SectionRelatedReading from './section-related-reading';
import SectionServicesCarousel from './section-services-carousel';
import SectionServicesInfobox from './section-services-infobox';
import SectionTabNav from './section-tab-nav';
import SectionWhatToExpect from './section-what-to-expect';
import BlockImages from '../blocks/block-images';
import BlockQuote from '../blocks/block-quote';

// block statement collage used in the industry page

// Each case is the Content Type. Content Types are located in Contentful for each Section model.
const sectionConductor = (props, key, extraProps) => {
  switch (props.__typename) {
    // Seperate SectionOpener conductor
    case 'ContentfulSectionOpener':
      return sectionOpenerConductor(props, key, extraProps);

    // Case Studies Page
    case 'ContentfulSectionCuratedCaseStudies':
      return <SectionCuratedCaseStudies key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionCaseStudiesLists':
      return <SectionCaseStudiesLists key={key} {...props} {...extraProps} />;
    // --------------------

    case 'ContentfulSectionOfficeList':
      return <SectionOfficeList key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionOurClients':
      return <SectionOurClients key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionCta':
      return <SectionCTA key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionManifesto':
      return <SectionManifesto key={key} {...props} {...extraProps} />;

    // Global Sections
    // --------------------

    case 'ContentfulSectionServicesInfobox':
      return <SectionServicesInfobox key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionClientProjectList':
      return <SectionClientProjectsList key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionHowWeWork':
      return <SectionHowWeWork key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionArrowText':
      return <SectionArrowText key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionTabNav':
      return <SectionTabNav key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionServiceCarousel':
      return <SectionServicesCarousel key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionOurApproach':
      return <SectionOurApproach key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionOurServices':
      return <SectionOurServices key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionManifestoCta':
      return <SectionManifestoCTA key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionGenericCta':
      return <SectionGenericCTA key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionRelatedReading':
      return <SectionArticleTeaser key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionHomeCarousel':
      return <SectionHomeCarousel key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionNumberedList':
      return <SectionNumberedList key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionHomepageMessage':
      return <SectionHomepageMessage key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionContactUs':
      return <SectionContactUs key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionCollage':
      return <SectionCollage key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionJobList':
      return <SectionJobList key={key} {...props} {...extraProps} />;

    // block-statement used in the industry page
    case 'ContentfulBlockStatementCollage':
      return <BlockStatementCollage key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionWhatToExpect':
      return <SectionWhatToExpect key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionKeyFacts':
      return <SectionKeyFacts key={key} {...props} {...extraProps} />;

    case 'ContentfulSectionMasterclassForm':
      return <SectionMasterclassForm key={key} {...props} {...extraProps} />;

    // Blocks in use on the Masterclass Landing pages
    case 'ContentfulBlockImageS':
      return <BlockImages key={key} {...props} />;

    // Blocks in use on the Masterclass Landing pages
    case 'ContentfulBlockQuote':
      return <BlockQuote key={key} {...props} />;

    // Default Fallback
    default:
      return null;
  }
};

export default sectionConductor;
