import React from 'react';

import Link from '../components/link';
import Markdown from '../components/markdown';
import { getLink } from '../urls';

const SectionGenericCTA = (props) => {
  const { colorSchema, text, ctaLabel, ctaUrl } = props;

  return (
    <div className={`section section-generic-cta section section-generic-cta--${colorSchema}`}>
      <div className="section-generic-cta__copy">
        <Markdown className="section-generic-cta__text" markdown={text.childMarkdownRemark.html} />
        {ctaUrl ? (
          <a
            className="button button--inverted"
            href={ctaUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            {ctaLabel}
          </a>
        ) : (
          <Link className="button button--inverted" link={getLink(props)} />
        )}
      </div>
    </div>
  );
};

export default SectionGenericCTA;
