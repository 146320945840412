import React from 'react';

import { DELAY_OFFSET } from '../components/animations/config';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import Image from '../components/image';
import Link from '../components/link';
import Markdown from '../components/markdown';
import { resolve } from '../urls';

const SectionContactUs = (props) => {
  const { heading, label, body, ctaButtonText, ctaButtonTarget, photoOne, photoTwo, photoThree } =
    props;

  return (
    <div className="section section-contact-us">
      <div className="section-contact-us__content-container">
        <GroupAnimation className="section-contact-us__left">
          <GroupedBlockAnimation as="p" className="section-contact-us__label label">
            {label}
          </GroupedBlockAnimation>
          <GroupedTextAnimation as="h2" className="section-contact-us__title" delay={DELAY_OFFSET}>
            {heading}
          </GroupedTextAnimation>
          <Markdown markdown={body.body} className="section-contact-us__body">
            {(text) => (
              <p>
                <GroupedTextAnimation delay={DELAY_OFFSET * 2}>{text}</GroupedTextAnimation>
              </p>
            )}
          </Markdown>
          <GroupedBlockAnimation className="section-contact-us__link" delay={DELAY_OFFSET * 3}>
            {ctaButtonTarget.includes('mailto') ? (
              <a href={ctaButtonTarget} className="button">
                {ctaButtonText}
              </a>
            ) : (
              <Link to={resolve('offices:list')} className="button">
                {ctaButtonText}
              </Link>
            )}
          </GroupedBlockAnimation>
        </GroupAnimation>
        <div className="section-contact-us__right">
          <GroupAnimation className="contact-collage">
            <GroupedBlockAnimation className="contact-collage__top">
              <Image image={photoOne} alt={photoOne.title} />
            </GroupedBlockAnimation>
            {photoTwo && photoThree && (
              <div className="contact-collage__bottom">
                <GroupedBlockAnimation
                  className="contact-collage__bottom-left"
                  delay={DELAY_OFFSET * 3}
                >
                  <Image image={photoTwo} alt={photoTwo.title} />
                </GroupedBlockAnimation>
                <GroupedBlockAnimation
                  className="contact-collage__bottom-right"
                  delay={DELAY_OFFSET * 5}
                >
                  <Image image={photoThree} alt={photoThree.title} />
                </GroupedBlockAnimation>
              </div>
            )}
          </GroupAnimation>
        </div>
      </div>
    </div>
  );
};

export default SectionContactUs;
