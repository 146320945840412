import { graphql, useStaticQuery } from 'gatsby';
import { string, bool } from 'prop-types';
import React, { useMemo } from 'react';

import {
  AccordionBase,
  AccordionButton,
  AccordionHeading,
  AccordionItem,
  AccordionPanel,
} from '../components/accordion';
import Link from '../components/link';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';
import { groupJobsByOffice, normalizePersonioJobData } from '../helpers';
import { resolve } from '../urls';

const QUERY_JOBS = graphql`
  query {
    allJobsJson {
      edges {
        node {
          office
          name
          id
        }
      }
    }
  }
`;

const SectionJobList = ({ id, heading, filterByCity = false, city = null }) => {
  const data = useStaticQuery(QUERY_JOBS);

  const tabItems = useMemo(() => {
    const jobs = data.allJobsJson.edges.filter(
      (job) => !filterByCity || !job.node.office || job.node.office === city
    );
    return groupJobsByOffice(jobs);
  }, [data]);

  return (
    <div className="section section-job-list">
      <GroupAnimation className="section-job-list__container">
        <GroupedTextAnimation as="h2" className="label section-job-list__headline">
          {heading}
        </GroupedTextAnimation>

        <AccordionBase
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={filterByCity && city ? [city] : []}
        >
          {tabItems.map(({ office, jobs }, index) => (
            <GroupedBlockAnimation
              key={office}
              className="section-job-list__animation"
              delay={DELAY_OFFSET * index}
            >
              <AccordionItem uuid={office}>
                <AccordionHeading>
                  <AccordionButton>
                    <span className="section-job-list__button">
                      <span className="section-job-list__button__label">{office}</span>
                      <span className="section-job-list__button__number">{jobs.length}</span>
                    </span>
                  </AccordionButton>
                </AccordionHeading>
                <AccordionPanel>
                  <ul className="section-job-list__panel">
                    {jobs.map(({ name, id }) => (
                      <li key={id} className="section-job-list__item">
                        <Link
                          className="section-job-list__link"
                          to={resolve('jobs:detail', { jobSlug: id })}
                        >
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </AccordionPanel>
              </AccordionItem>
            </GroupedBlockAnimation>
          ))}
        </AccordionBase>
      </GroupAnimation>
    </div>
  );
};

export default SectionJobList;

SectionJobList.propTypes = {
  id: string.isRequired,
  heading: string.isRequired,
  filterByCity: bool,
  city: string,
};
