/* globals FormData fetch */

import React, { useState } from 'react';
import Form from '../components/form';
import { useStaticQuery, graphql } from 'gatsby';
import { GroupAnimation, GroupedTextAnimation } from '../components/animations/group-animation';
import Image from '../components/image';
import serialize from 'form-serialize';
import axios from 'axios';

const SectionMasterclassForm = ({
  title,
  thankYouText = 'Thank you for your application to the masterclass!',
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formSubmitHandler = (form) => {
    const data = new FormData(form);
    fetch('/work-awesome-masterclass', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(data).toString(),
    })
      .then((response) => {
        setIsFormSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const query = graphql`
    query {
      allContentfulPartFooter(filter: { title: { ne: "dummy-info" } }) {
        edges {
          node {
            title
            image {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: CONSTRAINED, width: 900)
            }
          }
        }
      }
    }
  `;
  const imageQuery = useStaticQuery(query);
  const { image } = imageQuery.allContentfulPartFooter.edges[0].node;

  return (
    <div className="section-form__border-wrap" id="sign-up-form">
      <div className="form__main-image-wrapper">
        {image && <Image className="form__main-image" image={image} alt={image.title} />}
      </div>
      <GroupAnimation className={'section section-form'}>
        <GroupedTextAnimation className={'section-form__title'} as={'h2'}>
          {title}
        </GroupedTextAnimation>
        {isFormSubmitted ? (
          <GroupAnimation className={'form__inputs'}>
            <GroupedTextAnimation className={'form__inputs--submitted'} as={'p'}>
              {thankYouText}
            </GroupedTextAnimation>
          </GroupAnimation>
        ) : (
          <Form
            fullNameField
            emailFieldName
            companyFieldName
            roleFieldName
            motivationFieldName
            button_text={'Apply now'}
            consentCheckBoxes={[
              {
                label: {
                  childMarkdownRemark: {
                    html: [
                      'Agree to the <a href="/content/privacy-policy/" target="_blank">Privacy Policy</a>',
                    ],
                  },
                },
                required: true,
              },
              {
                label: {
                  childMarkdownRemark: {
                    html: ['I agree to receive monthly updates from Edenspiekermann'],
                  },
                },
                required: false,
              },
            ]}
            onSubmitted={formSubmitHandler}
          />
        )}
      </GroupAnimation>
    </div>
  );
};

export default SectionMasterclassForm;
