import React from 'react';

import Header from '../../components/header';
import Image from '../../components/image';
import SectionManifesto from '../section-manifesto';

import SectionOpener from './section-opener';

const SectionOpenerManifesto = (props) => {
  const { label, heading, image, items } = props;

  return (
    <SectionOpener openerType="manifesto">
      {image && (
        <div className="section-opener-manifesto__img-wrap">
          <Image className="section-opener-manifesto__img" image={image} alt={image.title} />
        </div>
      )}
      <div className="section-opener-manifesto__content-wrap">
        <Header label={label} heading={heading} />
        <SectionManifesto items={items} />
      </div>
    </SectionOpener>
  );
};

export default SectionOpenerManifesto;
