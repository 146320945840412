import React from 'react';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';
import Markdown from '../components/markdown';

const SectionOurServices = (props) => {
  const { heading, label, servicesLists } = props;
  return (
    <div className="section section-our-services">
      <GroupAnimation className="section-our-services__wrap">
        <div className="section-our-services__copy">
          <GroupedTextAnimation as="p" className="label">
            {label}
          </GroupedTextAnimation>
          <GroupedTextAnimation delay={DELAY_OFFSET} as="h2">
            {heading}
          </GroupedTextAnimation>
        </div>

        {servicesLists && (
          <div className="section-our-services__list-wrap">
            {servicesLists.map((list, i) => (
              <GroupedBlockAnimation
                className="section-our-services__list"
                key={i}
                delay={DELAY_OFFSET * 2 * i}
              >
                <h4>{list.topic}</h4>
                <Markdown
                  className="section-our-services__link"
                  markdown={list.itemsList.childMarkdownRemark.html}
                />
              </GroupedBlockAnimation>
            ))}
          </div>
        )}
      </GroupAnimation>
    </div>
  );
};

export default SectionOurServices;
