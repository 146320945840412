import React from 'react';
import Slider from 'react-slick';

import { DELAY_OFFSET } from '../components/animations/config';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import Image from '../components/image';
import Link from '../components/link';
import OfficeClock from '../components/office-clock';
import { resolve } from '../urls';

const SLIDER_SETTNGS = {
  className: 'office-list__slider',
  accessibility: true,
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  swipeToSlide: true,
  centerMode: true,
  centerPadding: '10%',
  slidesToShow: 1,
};

// the office card component rendered both in the grid and in the carousel (mobile)
const OfficeBlock = ({ officeItem, index }) => (
  <GroupedBlockAnimation className="office-list__block" delay={DELAY_OFFSET * index}>
    <Link to={resolve('offices:detail', { officeSlug: officeItem.slug })} className="office-tile">
      <div className="office-tile__overlay">
        <Image asBackground style={{ height: '100%' }} tag="div" image={officeItem.main_image} />
      </div>
      <div className="office-tile__details">
        {officeItem.city}
        <div className="office-tile__clock">
          <OfficeClock timezone={officeItem.time_zone} variant="light" />
        </div>
      </div>
      <div className="office-tile__more">
        <span className="button-icon">More</span>
      </div>
    </Link>
  </GroupedBlockAnimation>
);

const SectionOfficeList = (props) => (
  <div className="section section-office-list">
    <div className="office-list__container">
      <div className="office-list__meta">
        <GroupAnimation as="div" className="meta-lockup">
          <GroupedBlockAnimation className="meta-lockup__category">
            {props.label}
          </GroupedBlockAnimation>
          <GroupedTextAnimation as="h2" className="meta-lockup__title" delay={DELAY_OFFSET}>
            {props.heading}
          </GroupedTextAnimation>
          <GroupedTextAnimation
            as="p"
            className="meta-lockup__description"
            delay={DELAY_OFFSET * 2}
          >
            {props.text.text}
          </GroupedTextAnimation>
        </GroupAnimation>
      </div>
      <GroupAnimation>
        <Slider {...SLIDER_SETTNGS}>
          {props.offices &&
            props.offices.length > 0 &&
            props.offices.map((node, i) => <OfficeBlock officeItem={node} key={i} index={3} />)}
        </Slider>
        <div className="office-list__container-desktop">
          {props.offices.map((node, i) => (
            <OfficeBlock officeItem={node} key={i} index={3 + i} />
          ))}
        </div>
      </GroupAnimation>
    </div>
  </div>
);

export default SectionOfficeList;
