import React from 'react';

import Header from '../../components/header';

import SectionOpener from './section-opener';

const SectionOpenerBasic = (props) => (
  <SectionOpener openerType="case-studies">
    <Header
      label={props.label}
      heading={props.heading}
      introduction={props.introduction ? props.introduction.childMarkdownRemark.html : null}
    />
  </SectionOpener>
);

export default SectionOpenerBasic;
