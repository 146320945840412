import { shape, string } from 'prop-types';
import React from 'react';
import SectionOpener from './section-opener';
import Image from '../../components/image';
import Markdown from '../../components/markdown';
import { imageType } from '../../types/image';
import { GroupAnimation, GroupedTextAnimation } from '../../components/animations/group-animation';
import { DELAY_OFFSET } from '../../components/animations/config';

const SectionOpenerBasicImageRichText = ({ image, heading, label, text, introduction }) => {
  return (
    <SectionOpener openerType="basic-image-richtext">
      <section className="section-opener-basic-image-richtext">
        <Image
          className="section-opener-basic-image-richtext__img"
          role="img"
          aria-label={image.description || ''}
          title={image.title || ''}
          image={image}
        />
        <div className="section-opener-basic-image-richtext__wrapper">
          <div className="section-opener-basic-image-richtext__container">
            <GroupAnimation className="section-opener-basic-image-richtext__content">
              <h1>
                <GroupedTextAnimation className="label section-opener-basic-image-richtext__label">
                  {label}
                </GroupedTextAnimation>
                <GroupedTextAnimation delay={DELAY_OFFSET}>{heading}</GroupedTextAnimation>
              </h1>
              {text && (
                <GroupedTextAnimation
                  delay={DELAY_OFFSET * 2}
                  className="section-opener-basic-image-richtext__text"
                  as="p"
                >
                  <Markdown markdown={text.childMarkdownRemark.html} />
                </GroupedTextAnimation>
              )}
              {introduction && (
                <GroupedTextAnimation
                  delay={text ? DELAY_OFFSET * 3 : DELAY_OFFSET * 2}
                  className="section-opener-basic-image-richtext__text"
                  as="p"
                >
                  <Markdown markdown={introduction.childMarkdownRemark.html} />
                </GroupedTextAnimation>
              )}
            </GroupAnimation>
          </div>
        </div>
      </section>
    </SectionOpener>
  );
};

export default SectionOpenerBasicImageRichText;

SectionOpenerBasicImageRichText.propTypes = {
  image: imageType,
  heading: string.isRequired,
  label: string.isRequired,
  text: shape({
    childMarkdownRemark: shape({
      html: string,
    }),
  }),
  introduction: shape({
    childMarkdownRemark: shape({
      html: string,
    }),
  }),
};

SectionOpenerBasicImageRichText.defaultProps = {
  text: { childMarkdownRemark: { html: '' } },
  introduction: { childMarkdownRemark: { html: '' } },
};
